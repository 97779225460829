<template>
  <b-container fluid>
    <product-list></product-list>
  </b-container>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
export default {
  components: {
    "product-list": ProductList
  }
};
</script>
