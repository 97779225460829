<template>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col>
        <b-button @click="openCreateProductModal">{{ $t("goodsListGroup.create.addingNewProduct") }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalCount"
          :per-page="pagination.limit"
          @input="onChangeCurrentPage($event, uploadGoodsInfo)"
        ></b-pagination>
        <b-table
          :fields="fields"
          :items="items"
          responsive
          no-local-sorting
          @sort-changed="onSortChanged($event, uploadGoodsInfo)"
        >
          <template v-slot:top-row>
            <td>
              <b-input
                v-model.trim="filters.title"
                :placeholder="$t('goodsListGroup.name')"
                @change="onChangeFilterValue(filters, 'title', $event, uploadGoodsInfo)"
              ></b-input>
            </td>
            <td>
              <b-input
                v-model.trim="filters.code"
                :placeholder="$t('goodsListGroup.code')"
                @change="onChangeFilterValue(filters, 'code', $event, uploadGoodsInfo)"
              ></b-input>
            </td>
          </template>
          <template v-slot:cell(title)="row">
            <b-link @click="onOpenEditProductModal(row.item.id)">{{ row.value[$i18n.locale] }}</b-link>
          </template>
          <template v-slot:cell(hasSerialNumber)="row">
            <b-form-checkbox v-model="row.item.hasSerialNumber" disabled />
          </template>
          <template v-slot:cell(group)="row">{{ getGroups(row.item.groups) }}</template>
          <template v-slot:cell(includeInAllTemplates)="row">
            <b-form-checkbox v-model="row.item.includeInAllTemplates" disabled />
          </template>
          <template v-slot:cell(isSaleable)="row">
            <b-form-checkbox v-model="row.item.isSaleable" disabled />
          </template>
          <template v-slot:cell(imageId)="row">
            <b-form-checkbox :checked="row.item.imageId != null" disabled />
          </template>
        </b-table>
        <product-modal />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProductModal from "@/components/ProductModal.vue";

import { UPDATE_PRODUCT, OPEN_EDIT_PRODUCT_MODAL, OPEN_CREATE_PRODUCT_MODAL } from "@/const/events.js";

import paginationMixin from "@/mixins/pagination-mixin.js";

export default {
  mixins: [paginationMixin],
  components: {
    "product-modal": ProductModal
  },
  data() {
    return {
      items: [],
      filters: {
        title: "",
        code: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("goodsListGroup.name"), sortable: true },
        { key: "code", label: this.$t("goodsListGroup.code"), sortable: true },
        { key: "group", label: this.$t("goodsListGroup.groups") },
        { key: "hasSerialNumber", label: this.$t("goodsListGroup.serialNumber") },
        { key: "includeInAllTemplates", label: this.$t("goodsListGroup.includeInAllTemplates") },
        { key: "isSaleable", label: this.$t("goodsListGroup.isSaleable") },
        { key: "imageId", label: this.$t("goodsListGroup.havePhoto") }
      ];
    }
  },
  methods: {
    getGroups(row) {
      var groupString = "";
      for (var i = 0; i < row.length; i++) {
        groupString += row[i].title[this.$i18n.locale] + ";";
      }

      return groupString;
    },
    onOpenEditProductModal(productId) {
      this.$bus.emit(OPEN_EDIT_PRODUCT_MODAL, { id: productId });
    },
    uploadGoodsInfo() {
      this.$store
        .dispatch("goods/getGoods", {
          ...this.paginationParams,
          title: this.filters.title,
          code: this.filters.code,
          locale: this.$i18n.locale
        })
        .then(resp => {
          this.items = resp.data.items;
          this.pagination.totalCount = resp.data.totalCount;
        });
    },
    openCreateProductModal() {
      this.$bus.emit(OPEN_CREATE_PRODUCT_MODAL);
    }
  },
  watch: {
    "$i18n.locale"() {
      this.uploadGoodsInfo();
    }
  },
  mounted() {
    this.$bus.on(UPDATE_PRODUCT, this.uploadGoodsInfo);
    this.uploadGoodsInfo();
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_PRODUCT, this.uploadGoodsInfo);
  }
};
</script>
