<template>
  <div>
    <b-modal
      id="product-modal"
      :title="isEditMode ? $t('goodsListGroup.edit.editingProduct') : $t('goodsListGroup.create.addingNewProduct')"
      :ok-title="isEditMode ? $t('goodsListGroup.save') : $t('goodsListGroup.save')"
      :cancel-title="isEditMode ? $t('goodsListGroup.cancel') : $t('goodsListGroup.cancel')"
      @hidden="reset"
      @ok="onOk"
    >
      <template v-slot:default>
        <b-form-group :label="$t('goodsListGroup.name')">
          <b-form-input v-model.lazy="product.title[$i18n.locale]"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('goodsListGroup.code')">
          <b-form-input v-model.lazy="product.code"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('goodsListGroup.groups')">
          <v-select
            multiple
            v-model="selectedGroups"
            :options="groupsTitles"
            :reduce="title => title[$i18n.locale]"
            :label="$i18n.locale"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-row class="ml-1">
          <label class="mr-3">{{ $t("goodsListGroup.serialNumber") }}</label>
          <b-checkbox v-if="isEditMode" v-model="product.hasSerialNumber" disabled />
          <b-checkbox v-else v-model="product.hasSerialNumber" @change="if ($event) product.isSaleable = false;" />
        </b-row>
        <b-row class="ml-1">
          <label class="mr-3">{{ $t("goodsListGroup.saleable") }}</label>
          <b-checkbox v-model="product.isSaleable" :disabled="product.hasSerialNumber" />
        </b-row>
        <b-row class="ml-1">
          <label class="mr-3">{{ $t("goodsListGroup.includeInAllTemplates") }}</label>
          <b-checkbox v-model="product.includeInAllTemplates" />
        </b-row>
        <b-row>
          <b-col>
            <b-form-file
              v-model="selectedImage"
              :placeholder="$t('goodsListGroup.selectOrDropFile')"
              accept="image/*"
              :browse-text="$t('goodsListGroup.selectFile')"
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <thumbnail-image :imageId="product.imageId" />
          </b-col>
        </b-row>
        <div class="my-2" v-show="isEditMode">
          <b-button variant="danger" @click="onRemoveProduct">{{ $t("goodsListGroup.edit.remove") }}</b-button>
          <b-modal
            id="remove-product-modal"
            :title="$t('goodsListGroup.edit.remove')"
            :ok-title="$t('goodsListGroup.edit.remove')"
            :cancel-title="$t('goodsListGroup.cancel')"
            ok-variant="danger"
            :ok-disabled="productTitleForRemove !== product.title[$i18n.locale]"
            @ok="removeProduct"
          >
            <template v-slot:default>
              <b-form-group :label="$t('goodsListGroup.edit.removeBody')">
                <b-form-input v-model="productTitleForRemove"></b-form-input>
              </b-form-group>
            </template>
          </b-modal>
        </div>
      </template>
    </b-modal>
    <image-modal />
  </div>
</template>

<script>
import { EN, RU, LT } from "@/const/langs.js";
import { UPDATE_PRODUCT, OPEN_EDIT_PRODUCT_MODAL, OPEN_CREATE_PRODUCT_MODAL } from "@/const/events.js";
import mapper from "@/services/mapper.js";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";
import { mapActions } from "vuex";

export default {
  components: {
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  data() {
    return {
      isEditMode: false,
      oldProductId: 0,
      oldProductTitle: {},
      oldProductCode: "",
      oldProductGroups: [],
      oldProductHasSerialNumber: false,
      oldProductIsSaleable: false,
      oldProductIncludeInAllTemplates: false,
      oldLocale: "",
      groupsTitles: [],
      product: {
        title: {
          ru: "",
          en: "",
          lt: ""
        },
        code: "",
        hasSerialNumber: false,
        isSaleable: false,
        includeInAllTemplates: false,
        groups: []
      },
      productTitleForRemove: "",
      selectedImage: null
    };
  },
  computed: {
    selectedGroups: {
      get: function() {
        return this.product.groups.map(x => x.title);
      },
      set: function(values) {
        this.product.groups = this.groupsTitles
          .filter(x => values.some(y => y === x[this.$i18n.locale]))
          .map(x => {
            return { title: x };
          });
      }
    },
    options() {
      return this.groupsTitles
        .filter(x => this.product.groups.every(y => y.title !== x))
        .map(x => x[this.$i18n.locale]);
    }
  },
  methods: {
    ...mapActions(["goods/getGroupsTitles", "goods/addProduct", "goods/getGoodsById", "goods/update", "goods/remove"]),
    reset() {
      this.isEditMode = false;
      this.oldProductId = 0;
      this.oldProductTitle = {};
      this.oldProductCode = "";
      this.oldProductGroups = [];
      this.oldProductHasSerialNumber = false;
      this.oldProductIsSaleable = false;
      this.oldProductIncludeInAllTemplates = false;
      this.oldLocale = "";
      this.productTitleForRemove = "";
      this.product = {
        title: {
          ru: "",
          en: "",
          lt: ""
        },
        code: "",
        hasSerialNumber: false,
        includeInAllTemplates: false,
        groups: []
      };
      this.selectedImage = null;
    },
    resetSaleable(event) {
      if (event) this.product.isSaleable = false;
    },
    onOk(e) {
      e.preventDefault();

      if (this.product.title[this.$i18n.locale] === "") {
        alert(this.$t("goodsListGroup.emptyProductTitleErrorMsg"));
        return;
      }

      if (this.product.code === "") {
        alert(this.$t("goodsListGroup.emptyProductCodeErrorMsg"));
        return;
      }

      if (this.isEditMode) {
        // update product
        var productForUpdate = {
          locale: this.oldLocale,
          oldProductTitle: this.oldProductTitle,
          oldProductCode: this.oldProductCode,
          oldProductGroups: this.oldProductGroups,
          oldProductHasSerialNumber: this.oldProductHasSerialNumber,
          oldProductIsSaleable: this.oldProductIsSaleable,
          oldProductIncludeInAllTemplates: this.oldProductIncludeInAllTemplates,
          newProductTitle: this.product.title,
          newProductGroups: this.product.groups,
          newProductCode: this.product.code,
          newProductSerialNumber: this.product.serialNumber,
          newProductIsSaleable: this.product.isSaleable,
          newProductIncludeInAllTemplates: this.product.includeInAllTemplates
        };

        let vm = mapper.toFormData(productForUpdate, null, "");
        if (this.selectedImage) {
          vm.append("image", this.selectedImage, this.selectedImage.name);
        }
        this["goods/update"](vm)
          .then(() => {
            this.$bus.emit(UPDATE_PRODUCT);
            this.$bvModal.hide("product-modal");
          })
          .catch(() => alert(this.$t("goodsListGroup.updatingError")));
      } else {
        // create new product
        let title = {
          [EN]: this.product.title[this.$i18n.locale] + `_${EN}`,
          [RU]: this.product.title[this.$i18n.locale] + `_${RU}`,
          [LT]: this.product.title[this.$i18n.locale] + `_${LT}`
        };

        title[this.$i18n.locale] = this.product.title[this.$i18n.locale];

        this.product.title = title;

        let vm = mapper.toFormData(this.product, null, "");
        if (this.selectedImage) {
          vm.append("image", this.selectedImage, this.selectedImage.name);
        }
        vm.append("locale", this.$i18n.locale);
        this["goods/addProduct"](vm)
          .then(() => {
            this.$bus.emit(UPDATE_PRODUCT);
            this.$bvModal.hide("product-modal");
          })
          .catch(() => alert(this.$t("goodsListGroup.addingError")));
      }
    },
    uploadProductData({ id }) {
      this["goods/getGoodsById"](id).then(resp => {
        this.product = resp.data;
        this.isEditMode = true;
        this.oldProductId = this.product.id;
        this.oldProductTitle = Object.assign({}, this.product.title);
        this.oldProductCode = this.product.code.slice(0);
        this.oldProductGroups = this.product.groups.slice(0);
        this.oldProductHasSerialNumber = this.product.hasSerialNumber;
        this.oldProductIsSaleable = this.product.isSaleable;
        this.oldProductIncludeInAllTemplates = this.product.includeInAllTemplates;

        this.oldLocale = this.$i18n.locale;
        this.$bvModal.show("product-modal");
      });
    },
    onRemoveProduct(e) {
      e.preventDefault();
      this.$bvModal.show("remove-product-modal");
    },
    removeProduct() {
      this["goods/remove"](this.oldProductId)
        .then(() => {
          this.$bus.emit(UPDATE_PRODUCT);
          this.$bvModal.hide("remove-product-modal");
          this.$bvModal.hide("product-modal");
        })
        .catch(() => alert(this.$t("goodsListGroup.removingError")));
    },
    openModal() {
      this.$store
        .dispatch("goods/generateGoodsCode")
        .then(resp => (this.product.code = resp.data))
        .finally(() => this.$bvModal.show("product-modal"));
    }
  },
  mounted() {
    this["goods/getGroupsTitles"]().then(resp => (this.groupsTitles = resp.data));
    this.$bus.on(OPEN_EDIT_PRODUCT_MODAL, this.uploadProductData);
    this.$bus.on(OPEN_CREATE_PRODUCT_MODAL, this.openModal);
  },
  beforeDestroy() {
    this.$bus.off(OPEN_EDIT_PRODUCT_MODAL, this.uploadProductData);
    this.$bus.off(OPEN_CREATE_PRODUCT_MODAL, this.openModal);
  }
};
</script>
