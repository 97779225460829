function toFormData(obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      formKey = namespace ? namespace + "[" + property + "]" : property;
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (Array.isArray(obj[property]) && obj[property].length === 0) {
        fd.append(formKey, []);
      } else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        toFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
}
export default {
  toFormData
};
